<section class="page-content">
    <div class="container">
        <h4 class="mb-3">Important Note</h4>
        <ul>
            <li>This warranty is applicable to KDK products purchased from KDK FANS (M) SDN BHD
                and its authorized dealers within Malaysia only.</li>
            <li>Maximum up to 20 products per warranty submission.</li>
            <li>Required fields are marked</li>
        </ul>
      
        <h4 class="mb-3 mt-5">Product Information</h4>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">Model*</label>
                    <p-dropdown  (onChange)="onCheckRequiresSerialNo(selectedModelList)" 
                    [options]="modelList" [(ngModel)]="selectedModelList" 
                    [ngModelOptions]="{standalone: true}" 
                    optionLabel="modelname" optionValue="modelname" placeholder="&nbsp;"  class="{{form.value?.product_array.length == 2? 'ng-invalid ng-dirty': ''}}" 
                    [filter]="true" filterBy="modelname" [showClear]="true"></p-dropdown>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="serialNo">9 Digits Serial Number/Batch Number*</label>
                    <p-inputMask mode="decimal"
                    mask="999999999"
                    [disabled]="disableSerialNoInput"
                    [(ngModel)]="selectedSerialNo" 
                    [ngModelOptions]="{standalone: true}" 
                    placeholder="&nbsp;" 
                    class="{{form.value?.product_array.length == 2? 'ng-invalid ng-dirty': ''}}"
                    ></p-inputMask>
                    <!-- <input type="text" [disabled]="disableSerialNoInput" pInputText [(ngModel)]="selectedSerialNo" [ngModelOptions]="{standalone: true}" placeholder="&nbsp;" class="{{form.value?.product_array.length == 2? 'ng-invalid ng-dirty': ''}}"> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    &nbsp;
                    <div>
                        <p-button (click)="onAddModel();" label="Add to Warranty" icon="pi pi-plus" iconPos="left"  styleClass="p-button-danger"></p-button>
                        <!-- <p-button (click)="openQrScanner();" label="" icon="pi pi-qrcode" iconPos="left"  styleClass="p-button-danger"></p-button> -->
                    </div>
                </div>
            </div>
        </div>
        <table class="table mt-4" *ngIf="productList.length > 0">
            <tr>
                <th width="50">&nbsp;</th>
                <th width="50">No</th>
                <th>Model</th>
                <th>Serial Number</th>
            </tr>
            <tr *ngFor="let data of productList; index as i">
                <td><i class="pi pi-times-circle" (click)="onRemoveModel(i)"></i></td>
                <td>{{i+1}}</td>
                <td>{{data.modelNo}}</td>
                <td>{{data.serialNo}}</td>
            </tr>
        </table>

        <form [formGroup]="form">
        <h4 class="mb-3 mt-5">Purchase Information</h4>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">Purchase From (Seller Company Name)*</label>
                    <input type="text" pInputText  formControlName="purchaseFrom" placeholder="&nbsp;" class="{{finalFormData?.purchaseFrom ==''? 'ng-invalid ng-dirty': ''}}">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="serialNo">Purchase Date*</label>
                    <p-calendar [(ngModel)]="purchaseDate"  [maxDate]="maxDate"  [ngModelOptions]="{standalone: true}" dateFormat="dd/mm/yy" [showIcon]="true" inputId="icon" styleClass="p-button-danger" class="{{finalFormData?.purchaseDate ==''? 'ng-invalid ng-dirty': ''}}"></p-calendar>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="serialNo">Proof of Purchase* <small>(Maximum file size 5mb)</small></label>
                    <input type="file" #inputFile (change)="onUploadDocument($event)" accept="*" required class="{{finalFormData?.purchaseProof ==''? 'ng-invalid ng-dirty': ''}}">

                    <!-- <p-fileUpload mode="basic" #inputFile chooseLabel="Upload Proof of Purchase" (change)="onUploadDocument($event)" accept="*"  styleClass="p-button-danger"></p-fileUpload> -->
                </div>
            </div>
        </div>

        <h4 class="mb-3 mt-5">Contact Information</h4>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mb-3">
                    <label for="model">First Name*</label>
                    <input type="text" pInputText formControlName="fname" placeholder="&nbsp;" class="{{finalFormData?.fname ==''? 'ng-invalid ng-dirty': ''}}">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mb-3">
                    <label for="model">Last Name*</label>
                    <input type="text" pInputText formControlName="lname" placeholder="&nbsp;" class="{{finalFormData?.lname ==''? 'ng-invalid ng-dirty': ''}}">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mb-3">
                    <label for="model">Email Address*</label>
                    <input type="email" pInputText formControlName="email"  placeholder="&nbsp;"  class="{{(finalFormData?.email =='')||(emailValidator == false && finalFormData?.email !='')? 'ng-invalid ng-dirty': ''}}">
                    <div *ngIf="emailValidator == false && finalFormData?.email !=''" class="text-danger">Invalid email format</div>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">Mobile Number*</label>
                    <p-inputMask mode="decimal"
                    type="tel"
                    mask="9?9999999999"
                    slotChar=" "
                    [maxlength]="11"
                    formControlName="mobileno" 
                    placeholder="&nbsp;" 
                    class="{{finalFormData?.mobileno ==''? 'ng-invalid ng-dirty': ''}}">
                </p-inputMask>
                    <!-- <input type="text" pInputText formControlName="mobileno" placeholder="&nbsp;" class="{{finalFormData?.mobileno ==''? 'ng-invalid ng-dirty': ''}}"> -->
                </div>
            </div>
            <div class="col-lg-12">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">Address Line 1*</label>
                    <input type="text" pInputText formControlName="address1" placeholder="&nbsp;" class="{{finalFormData?.address1 == ''? 'ng-invalid ng-dirty': ''}}">
                </div>
            </div>
            <div class="col-lg-12">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">Address Line 2</label>
                    <input type="text" pInputText formControlName="address2" placeholder="&nbsp;">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">City*</label>
                    <input type="text" pInputText formControlName="city" placeholder="&nbsp;" class="{{finalFormData?.city == ''? 'ng-invalid ng-dirty': ''}}">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">Postcode*</label>
                    <p-inputMask mode="decimal"
                    mask="99999"
                    slotChar=" "
                    formControlName="postcode" 
                    placeholder="&nbsp;" 
                    class="{{finalFormData?.postcode == ''? 'ng-invalid ng-dirty': ''}}">
                </p-inputMask>
                    <!-- <input type="text" pInputText formControlName="postcode" placeholder="&nbsp;" class="{{finalFormData?.postcode == ''? 'ng-invalid ng-dirty': ''}}"> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="d-flex flex-column gap-2 mt-3">
                    <label for="model">State*</label>
                    <!-- <input type="text" pInputText formControlName="state" placeholder="&nbsp;"> -->
                    <p-dropdown formControlName="state"  [(ngModel)]="selectedStateOption" optionValue="name" [showClear]="true" [options]="stateOption" optionLabel="name" placeholder="&nbsp;" class="{{finalFormData?.state == ''? 'ng-invalid ng-dirty': ''}}"></p-dropdown>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="d-flex align-items-start gap-2 mt-3 accept-tnc">
                    <p-checkbox formControlName="ischeck" [binary]="true" inputId="check" class="{{finalFormData?.ischeck == false? 'ng-invalid ng-dirty': ''}}"></p-checkbox>
                    <label for="check" class="d-grid">
                        <span>By submitting this form, you consent to us:</span>
                        <span>I have read the <a href="https://www.kdk.com.my/term-of-use/" target="_blank">Terms and Conditions</a> 
                            as well as the <a href="https://www.kdk.com.my/pdpa/" target="_blank">Privacy Policy</a>, and agree with them</span>
                        <span>I hereby agree to KDK (M) Sdn Bhd using and disclosing the personal information for product services 
                            including contact by phone, email, SMS or other electronic means.</span>

                    </label>
                </div>
            </div>
        </div>

        <div class="submit-btn">
            <a type="submit" (click)="warrantyReg()">Submit Warranty</a>
        </div>
        </form>
    </div>
</section>

<!-- <p-button (click)="open()" icon="pi pi-external-link" label="Show"></p-button> -->
<p-dialog header="QR Code Scanner" [(visible)]="QRvisible"  [modal]="true" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '500px' }" [draggable]="false" [resizable]="false">
    <div class="popup-qrscanner">
        <p-toggleButton (onChange)="onChangeCam($event)" onLabel="Switch Back Cam" offLabel="Switch Front Cam" onIcon="pi pi-camera" offIcon="pi pi-camera"></p-toggleButton>
        <div *ngIf="camChecked == 'back'">
            <ngx-scanner-qrcode #action="scanner" [config]="config"></ngx-scanner-qrcode>
            {{getValueQR(action)}}
            <p *ngIf="qrResult_model" class="mb-0"><strong>Model</strong>:{{qrResult_model}}</p>
            <p *ngIf="qrResult_serialno"><strong>Serial No</strong>: {{qrResult_serialno}}</p>
            <!-- Loading -->
            <!-- {{action.isLoading}} -->
            <p *ngIf="action.isLoading">⌛ Loading back camera...</p>
            <p-button id="myButton" (click)="action.isStart ? action.stop() : action.start()" label="{{action.isStart ? 'Stop' : 'Start'}}" icon="pi pi-qrcode" iconPos="left"  styleClass="p-button-danger mt-2"></p-button>
            <p-button (click)="qrAddModel();" [disabled]="qrAddModelBtn" label="Add to Warranty" icon="pi pi-plus" iconPos="left"  styleClass="p-button-danger"></p-button>
        </div>
        <div *ngIf="camChecked == 'front'">
            <ngx-scanner-qrcode #action="scanner"></ngx-scanner-qrcode>
            {{getValueQR(action)}}
            <p *ngIf="qrResult_model" class="mb-0"><strong>Model</strong>:{{qrResult_model}}</p>
            <p *ngIf="qrResult_serialno"><strong>Serial No</strong>: {{qrResult_serialno}}</p>
            <!-- Loading -->
            <p *ngIf="action.isLoading">⌛ Loading front camera...</p>
            <p-button id="myButton" (click)="action.isStart ? action.stop() : action.start()" label="{{action.isStart ? 'Stop' : 'Start'}}" icon="pi pi-qrcode" iconPos="left"  styleClass="p-button-danger mt-2"></p-button>
            <p-button (click)="qrAddModel();" [disabled]="qrAddModelBtn" label="Add to Warranty" icon="pi pi-plus" iconPos="left"  styleClass="p-button-danger"></p-button>
        </div>

        <!-- {{getValueQR(action)}}
        <p *ngIf="qrResult_model" class="mb-0"><strong>Model</strong>:{{qrResult_model}}</p>
        <p *ngIf="qrResult_serialno"><strong>Serial No</strong>: {{qrResult_serialno}}</p>
   
        <p *ngIf="action.isLoading">⌛ Loading...</p>
        <p-button (click)="action.isStart ? action.stop() : action.start()" label="{{action.isStart ? 'Stop' : 'Start'}}" icon="pi pi-qrcode" iconPos="left"  styleClass="p-button-danger mt-2"></p-button>
        <p-button (click)="qrAddModel();" [disabled]="qrAddModelBtn" label="Add to Warranty" icon="pi pi-plus" iconPos="left"  styleClass="p-button-danger"></p-button> -->

        <!-- <button (click)="action.isStart ? action.stop() : action.start()">{{action.isStart ? 'Stop' : 'Start'}}</button> -->
    </div>
</p-dialog>
